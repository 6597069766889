import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import { ApiTokensData } from '../../utils/localStorage';

export const API_TOKENS_RECEIVED = '[ACTION] API_TOKENS_RECEIVED';
export const API_TOKENS_FAILED = '[ACTION] API_TOKENS_FAILED';

export function requestApiTokens(tokens?: ApiTokensData) {
	return {
		type: API_TOKENS_RECEIVED,
		payload: tokens,
	};
}

export function requestApiTokensFailed(error: unknown) {
	return {
		type: API_TOKENS_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}
