import React from 'react';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import HttpService from '../../../../services/rest/HttpService';
import { itemsToOptions, itemToOption, optionToItem } from '../react-select-helpers/react-select.helpers';
import { remapEntitiesFromResponse } from '../../../Resources/translations/helpers/translations.helpers';
import { customOption } from '../../Blocky/partials/shared/custom-select-option';

type Properties = {
	t: any;
	entityType: string;
	inputLanguage: string;
	onChange: (entity: any, id: string) => void;
	value: any;
	id?: string;
};

const EntitySearch: React.FunctionComponent<Properties> = ({ entityType, inputLanguage, t, onChange, value, id }) => {
	const searchEntity = (search: string, callback: any) => {
		if (entityType && inputLanguage && search.length > 2) {
			HttpService.instanceFootball(inputLanguage.split('-')[0])
				.get(`/search?query=${search}&entity_type=${entityType}`)
				.then((response: any) => {
					callback(itemsToOptions(remapEntitiesFromResponse(response.data, entityType), false, t, entityType));
				})
				.catch((error: unknown) => console.error(error));
		}
	};

	return (
		<AsyncSelect
			inputId={`entity-search-input-${id ? id : ''}`}
			className='w-100'
			noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			value={value.name ? itemToOption(value, false, t, entityType) : []}
			loadOptions={_.debounce(searchEntity, 500)}
			placeholder={`${t('search')} ${t(entityType)}`}
			onChange={(selection: any) => onChange(optionToItem(selection), value.id)}
			formatOptionLabel={customOption}
		/>
	);
};

export default EntitySearch;
