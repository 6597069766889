import * as React from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { SelectOptions } from '../../../../../../constants/content-types';
import { useTranslation } from 'react-i18next';
import { FunctionComponent, useEffect, useState } from 'react';
import { getOptionsByContentType, getOptionsBySelectedQuery, Source } from './source-select.helper';
import HttpService from '../../../../../../services/rest/HttpService';

export type Properties = {
	onSourceSelect: Function;
	selectedSources: string[];
	isClearable: boolean;
	contentType: string;
};

const SourceSelect: FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const [sourceOptions, setSourceOptions] = useState<Source[] | null>(null);

	const { onSourceSelect, selectedSources, isClearable, contentType } = props;

	useEffect(() => {
		HttpService.getSources(`${contentType}s`).then((res: any) => {
			setSourceOptions(res.data.data);
		});
	}, []);

	const onPropertyChange = (values: ValueType<SelectOptions>) => {
		const selections = values as SelectOptions[];
		const selectedQueryParams: string[] = [];
		if (selections && selections.length > 0) {
			selections.forEach((selection) => {
				selection && selectedQueryParams.push(selection.value);
			});
		}
		onSourceSelect(selectedQueryParams);
	};

	return (
		<Select
			inputId='advance-filters-source-select'
			data-qa='advance-filters-source-select'
			value={getOptionsBySelectedQuery(selectedSources)}
			options={getOptionsByContentType(sourceOptions)}
			onChange={onPropertyChange}
			placeholder={t('select')}
			isClearable={isClearable}
			isMulti
		/>
	);
};

export default SourceSelect;
