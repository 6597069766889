import React from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import ImageListingContainer from '../../../../Partials/Sidebar/Media/subcomponents/media-main-image/subcomponents/image-listing/ImageListingContainer';
import Project from '../../../../../models/project/Project';
import Image from '../../../../../models/image/Image';
import AsyncImageContainer from '../../../../Partials/BaseComponents/AsyncImage/AsyncImageContainer';
import { Title } from '../../../../Partials/Fields/title/TitleComponent';
import { Summary } from '../../../../Partials/Fields/summary/SummaryComponent';
import { ContentOverrideModel } from './models/ContentOverrideModel';
import './Scss/ContentItemOverrideEdit.scss';
import { Link } from 'react-router-dom';
import { ContentTypes, ListContentTypes } from '../../../../../constants/content-types';
import DateTimeSelect from '../../../../Partials/Sidebar/general-content/subcomponents/date-time/date-time-select';
import { getListType } from '../../helpers/list.helper';
import ImageCropContainer from '../../../../Partials/Shared/image-crop/image-crop-container';
import moment from 'moment';

type Properties = {
	value: ContentOverrideModel;
	project: Project;
	itemIndex: number;
	t: any;
	onChange: (value: ContentOverrideModel) => any;
	onClose: () => any;
	rightSidebarList: boolean;
};

type State = {
	model: ContentOverrideModel;
	showCropModal: boolean;
	imageId: string;
};

export default class ContentItemOverrideEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			model: props.value,
			showCropModal: false,
			imageId: '',
		};
	}

	updateModelState(model: ContentOverrideModel) {
		this.setState({
			...this.state,
			model,
		});
	}

	onTitleChange(text: string) {
		let model = this.state.model;
		model.title = text;
		this.updateModelState(model);
	}

	onDescriptionChange(text: string) {
		let model = this.state.model;
		model.subtitle = text;
		this.updateModelState(model);
	}

	onImageChange(image: Image) {
		let model = this.state.model;
		model.imageId = image.id;
		this.updateModelState(model);
	}

	onDateFromUntilChange(dates: Date[] | null, property: 'from' | 'to') {
		const model = this.state.model;
		const selectedDate = dates && dates.length >= 0 ? dates[0] : '';
		const dateToString = moment(selectedDate).toISOString();
		model[property] = dateToString;
		this.updateModelState(model);
	}

	onSave() {
		const model = this.state.model;
		this.props.onChange(model);
	}

	toggleCropModal(imageId: string, isOpen: boolean) {
		this.setState({
			...this.state,
			showCropModal: isOpen,
			imageId,
		});
	}

	render() {
		const { t, project, itemIndex } = this.props;
		const { model, showCropModal } = this.state;

		return (
			<li className='list-group-item bg-light'>
				<div className='list-content'>
					<h6 className='text-truncate mb-3 py-2'>
						<i className={`fa ${model.icon} mr-2`} />
						<Link
							to={`/smp/${model.listItemType === ContentTypes.GALLERY ? 'gallerie' : model.listItemType.toLocaleLowerCase()}s/edit/${
								model.listItemId
							}`}
							className='text-dark mr-1'
						>
							{model.listItemTitle}
						</Link>
						<small className='font-weight-normal'>({t('original_title')})</small>
					</h6>
					<Row className={'mb-5'}>
						<Col cols={12} md={6}>
							<Row>
								<Col>
									<Label>{t('title')}</Label>
									<Title t={t} value={model.title} onChange={this.onTitleChange.bind(this)} />
								</Col>
							</Row>
							<Row>
								<Col>
									<Summary t={t} value={model.subtitle} showValidation={false} onChange={this.onDescriptionChange.bind(this)} />
								</Col>
							</Row>
						</Col>
						<Col cols={12} md={6}>
							<Row className='ml-5'>
								<Col cols={12} md={4}>
									<Row className={'mb-1'}>
										<Col>
											<div className={'position-relative'}>
												<AsyncImageContainer imageId={model.imageId} projectId={project.domain} />
												<Button
													id={`content-item-override-remove-btn-${itemIndex}`}
													color={'danger'}
													className={'text-light position-absolute top-0 right-0 remove-override-image'}
													onClick={() => {
														this.onImageChange({} as Image);
													}}
												>
													<i className={'fa fa-trash'}></i>
												</Button>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
							{model.imageId && (
								<Row className='ml-5 mt-2 w-50'>
									<Button
										id={`image-crop-btn-${model.imageId}`}
										block
										color='secondary'
										className='p-0 py-1 m-0'
										onClick={() => this.toggleCropModal(model.imageId, true)}
									>
										<i className={'fa fa-crop'}> </i>
									</Button>
								</Row>
							)}
							<ImageCropContainer
								t={t}
								imageId={model.imageId}
								currentProject={project}
								open={showCropModal}
								onClose={() => {
									this.toggleCropModal('', false);
								}}
							/>
							<Row className='ml-5 mt-2'>
								<ImageListingContainer onImageSelect={this.onImageChange.bind(this)} currentProject={project} withToggle={true} t={t} />
							</Row>
						</Col>
						{getListType() === ListContentTypes.SCHEDULED && (
							<Col cols={12} md={6}>
								<Row>
									<Col>
										<Label htmlFor='scheduled-list-add-from-input'>{t('add_from')}</Label>
										<DateTimeSelect
											t={t}
											id='scheduled-list-add-from-input'
											onChange={(date) => this.onDateFromUntilChange(date, 'from')}
											date={model.from || ''}
										/>
									</Col>
									<Col>
										<Label htmlFor='scheduled-list-add-until-input'>{t('added_until')}</Label>
										<DateTimeSelect
											t={t}
											id='scheduled-list-add-until-input'
											onChange={(date) => this.onDateFromUntilChange(date, 'to')}
											date={model.to || ''}
										/>
									</Col>
								</Row>
							</Col>
						)}
					</Row>
					<Row className={'mb-3'}>
						<Col>
							<div className='list-actions'>
								<Button
									id={`content-item-override-save-btn-${itemIndex}`}
									size={'sm'}
									color={'info'}
									onClick={() => {
										this.onSave();
										this.props.onClose();
									}}
								>
									<i className={'fa fa-save'}></i> {t('save')}
								</Button>
								<Button
									id={`content-item-override-close-btn-${itemIndex}`}
									size={'sm'}
									color={'danger'}
									className={'ml-1'}
									onClick={() => {
										this.props.onClose();
									}}
								>
									<i className={'fa fa-times'}></i> {t('close')}
								</Button>
							</div>
						</Col>
					</Row>
				</div>
			</li>
		);
	}
}
