import i18next from 'i18next';
import { CompetitionResponse } from '../../types/livescore';

export const sortCompetitionsByCountry = (competitions: CompetitionResponse[], t: i18next.TFunction) => {
	const competitionsByCountry = new Map();

	const getCountryInfo = (competition: CompetitionResponse) => {
		if (!competition.country) {
			return {
				name: t('tournament_filter_modal.empty_competition_name'),
				flagUrl: '',
			};
		}
		return {
			name: competition.country.name,
			flagUrl:
				(competition.country &&
					competition.country.display_asset &&
					competition.country.display_asset.url &&
					competition.country.display_asset.url) ||
				'',
		};
	};

	const transformCompetition = (comp: CompetitionResponse) => ({
		label: comp.name,
		value: comp.id,
		flag: (comp.country && comp.country.display_asset && comp.country.display_asset.url && comp.country.display_asset.url) || '',
		gender: comp.gender,
	});

	competitions.forEach((comp) => {
		const countryInfo = getCountryInfo(comp);
		if (!competitionsByCountry.has(countryInfo.name)) {
			competitionsByCountry.set(countryInfo.name, {
				flagUrl: countryInfo.flagUrl,
				competitions: [],
			});
		}
		competitionsByCountry.get(countryInfo.name).competitions.push(transformCompetition(comp));
	});

	const sortedResult = Array.from(competitionsByCountry.entries())
		.sort(([countryA], [countryB]) => countryA.localeCompare(countryB))
		.map(([country, data]) => ({
			country,
			flagUrl: data.flagUrl,
			competitions: data.competitions,
		}));

	return sortedResult;
};
