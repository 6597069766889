import React from 'react';
import { Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { requestUserBundle } from '../../../store/action-creators/UserBundleActionCreators';
import { LoginProperties, LoginState } from './properties/ComponentProps';
import { AppState, store } from '../../../store/store';
import { Unsubscribe } from 'redux';
import { clearErrors } from '../../../store/action-creators/GeneralActions';
import ErrorAsync from '../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import { withTranslation } from 'react-i18next';
import { connect } from '../../../store/store.connect';
import { requestApiTokens } from '../../../store/action-creators/ApiTokensCreators';
import { ApiTokensData } from '../../../utils/localStorage';

@connect<LoginProperties, AppState>(
	(state) => ({
		currentProject: state.project.currentProject,
		error: state.general.errors,
		auth: state.auth,
	}),
	(dispatch) => ({
		requestUserBundle: (username: string, password: string) => dispatch(requestUserBundle(username, password)),
		requestApiTokens: (tokens?: ApiTokensData) => dispatch(requestApiTokens(tokens)),
		clearErrors: () => dispatch(clearErrors()),
	}),
)
class Login extends React.Component<LoginProperties, LoginState> {
	private unsubscribeStore: Unsubscribe = () => undefined;
	private serverError: any;

	constructor(props: any) {
		super(props);
		this.state = {
			username: '',
			password: '',
			isLoading: false,
			redirectToReferer: false,
		};
	}

	componentDidMount() {
		this.props.clearErrors();
		this.unsubscribeStore = store.subscribe(() => {
			if (this.props.error) {
				this.setState({ isLoading: false });
			}
		});
		this.initPageTitle();
		this.props.requestApiTokens();
	}

	componentDidUpdate(prevProps: Readonly<LoginProperties>) {
		if (prevProps.auth !== this.props.auth) this.routeToReferer();

		if (this.props.error && this.props.error.errors[0] && this.props.error.errors[0].code == '500') {
			this.serverError = { ...this.props.error.errors[0], error: 'Cannot connect to the server!' };
		}
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	routeToReferer = () => {
		const { from } = this.props.location.state || { from: { pathname: '/project-select' } };

		if (this.props.auth && this.props.auth.isAuthenticated) {
			this.setState({ redirectToReferer: true });

			if (this.props.currentProject.id) {
				from.pathname !== '/' ? this.props.history.push(from) : this.props.history.push({ from: { pathname: '/dashboard' } });
			} else {
				this.props.history.push('/project-select');
			}
		}
	};

	initPageTitle = () => (document.title = this.props.t('login_title'));

	handleUsernameInput = (event: any) => {
		this.setState({ username: event.target.value });
		this.props.clearErrors();
	};

	handlePasswordInput = (event: any) => {
		this.setState({ password: event.target.value });
		this.props.clearErrors();
	};

	submit = () => {
		if (!this.state.isLoading) {
			this.props.requestUserBundle(this.state.username, this.state.password);
			this.setState({ isLoading: true });
			this.props.clearErrors();
		}
	};

	moveToNextTab = (event: any) => {
		if (event.keyCode === 13) {
			const form = event.target.form;
			const index = Array.prototype.indexOf.call(form, event.target);
			form.elements[index + 1].focus();
			event.preventDefault();
		}
	};

	onSubmit = (event: any) => {
		if (event.keyCode === 13 && this.state.password !== '' && !this.state.isLoading) {
			this.props.requestUserBundle(this.state.username, this.state.password);
			this.setState({ isLoading: true });
			this.props.clearErrors();
		}
	};

	render() {
		return (
			<div className='app flex-row align-items-center'>
				<Container>
					<Row className='justify-content-center'>
						<Col md='6'>
							<CardGroup>
								<Card className='p-4'>
									<CardBody>
										<Form>
											<h1>{this.props.t('login')}</h1>
											<p className='text-muted'>{this.props.t('sign_in_to_account')}</p>
											<InputGroup className='mb-3'>
												<InputGroupAddon addonType='prepend'>
													<InputGroupText>
														<i className='icon-user'></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													type='text'
													placeholder='Username'
													autoComplete='username'
													onChange={this.handleUsernameInput}
													onKeyDown={this.moveToNextTab}
												/>
											</InputGroup>
											<InputGroup className='mb-4'>
												<InputGroupAddon addonType='prepend'>
													<InputGroupText>
														<i className='icon-lock'></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													type='password'
													placeholder='Password'
													autoComplete='current-password'
													onChange={this.handlePasswordInput}
													onKeyDown={this.onSubmit}
												/>
											</InputGroup>
											<Row className='mb-3'>
												<Col xs='6'>
													<div
														className={`bg-primary btn btn-default ld-ext-right px-4 ${this.state.isLoading ? 'running disabled' : ''}`}
														onClick={this.submit}
													>
														{this.props.t('login')}
														<div className='ld ld-ring ld-spin'></div>
													</div>
												</Col>
											</Row>
											<Row>
												{this.props.error && (
													<Col xs='12'>
														<ErrorAsync t={this.props.t} errorType='error' customError={this.serverError} />
													</Col>
												)}
											</Row>
										</Form>
									</CardBody>
								</Card>
							</CardGroup>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withTranslation()(Login) as React.ComponentType;
