import { AVAILABLE_PROJECTS } from '../../constants/constants';
import { store } from '../store';
import Profile from '../../models/profile/Profile';
import ModelMapper from '../../models/ModelMapper';
import RequestMapper from '../../models/RequestMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';

import { REQUEST_USER_BUNDLE_SUCCESS, USER_PROJECTS_FAILED, USER_PROJECTS_RECEIVED } from '../action-creators/UserBundleActionCreators';
import { ADMIN_CATEGORY_LISTING_RECEIVED } from '../action-creators/CategoriesActionCreators';
import { LANGUAGE_UPDATE, PROFILE_FAILED, PROFILE_RECEIVED } from '../action-creators/ProfileActionCreators';
import { actionService } from '../../App';
import {
	PROJECT_FEATURES_RECEIVED,
	DISTRIBUTION_REGIONS_RECEIVED,
	DISTRIBUTION_CHANNELS_RECEIVED,
} from '../action-creators/ProjectResourcesActionCreators';
import { API_TOKENS_FAILED, API_TOKENS_RECEIVED } from '../action-creators/ApiTokensCreators';
import { setApiTokens } from '../../utils/localStorage';

export function storeAuthToken() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === REQUEST_USER_BUNDLE_SUCCESS) {
					action.payload = Profile.builder().withIsAuthenticated(true).build();
				}

				if (action.type === PROFILE_RECEIVED) {
					action.payload = ModelMapper.remapProfileFromResponse(action.payload.data.data, action.permissions.data.data);
				}

				if (action.type === PROFILE_FAILED) {
					console.log('MID PROFILE_FAILED');
				}

				if (action.type === PROJECT_FEATURES_RECEIVED) {
					action.payload = ModelMapper.remapResponseToFeatureList(action.payload);
				}

				if (action.type === DISTRIBUTION_REGIONS_RECEIVED) {
					action.payload = ModelMapper.remapResponseToContentAttributesList(action.payload);
				}

				if (action.type === DISTRIBUTION_CHANNELS_RECEIVED) {
					action.payload = ModelMapper.remapResponseToContentAttributesList(action.payload);
				}

				if (action.type === USER_PROJECTS_RECEIVED) {
					let profile = store.getState().profile;
					profile.isLoggedIn = true;
					action.payload.projects = ModelMapper.remapProjectsFromResponse(action.payload.data.data);
					action.payload.profile = profile;
				}

				if (action.type === USER_PROJECTS_FAILED) {
					console.log('MID USER_PROJECTS_FAILED');
				}

				if (action.type === ADMIN_CATEGORY_LISTING_RECEIVED) {
					action.payload = ModelMapper.remapCategoriesFromResponse(action.payload);
				}

				if (action.type === AVAILABLE_PROJECTS) {
					action.payload = ModelMapper.extractAvailableProjects(store.getState().project.projects, store.getState().project.currentProject);
				}

				if (action.type === LANGUAGE_UPDATE) {
					action.payload.originalProfile = action.payload.profile;
					action.payload.profile = RequestMapper.mapProfile(action.payload.profile);
					toast.success(i18n.t('language_changed'));
				}

				if (actionService) {
					actionService.emitAction(action.type);
				}

				if (action.type === API_TOKENS_RECEIVED && action.payload && action.payload.data && action.payload.data.access_token) {
					setApiTokens(action.payload.data.access_token);
				}

				if (action.type === API_TOKENS_FAILED) {
					toast.error(i18n.t('general_error_message'));
				}
			}

			return next(action);
		};
	};
}
