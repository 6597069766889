export interface Source {
	source: string;
}

export const getOptionsBySelectedQuery = (selectedQueries: string[]) => {
	return selectedQueries.map((selection) => {
		return {
			label: selection,
			value: selection,
		};
	});
};

export const getOptionsByContentType = (sources: Source[] | null) => {
	if (sources && sources.length > 0) {
		return sources.map((source) => {
			return {
				label: source.source,
				value: source.source,
			};
		});
	}

	return null;
};
