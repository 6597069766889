import React, { FC, useEffect, useState } from 'react';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { mapResponseListToPlayerSeasonsModel, playerSeasonToOption } from '../helpers/player-select/player-season-select-component.helper';
import Select from 'react-select';
import { createTournamentGroupOptions } from '../../../partials/player-seasons/helpers/players-seasons-select.helper';
import { customOption } from '../../../partials/shared/custom-select-option';
import EntitySeasonsSelectModel from '../../../partials/player-seasons/model/entity-seasons-select.model';
import { useTranslation } from 'react-i18next';
import { IPlayerInformationResponse, IPlayerStatisticsResponse, IPlayerTeam, ISelectOption } from '../../types/player';
import { Seasons } from '../../types/seasons';
interface Props {
	id: string;
	player: string;
	selectedSeason: EntitySeasonsSelectModel;
	onPlayerTeamsSelect: (teams: string[]) => void;
	onSeasonSelect: (season: EntitySeasonsSelectModel | null) => void;
}

const PlayerSeasonStatisticsSelect: FC<Props> = (props) => {
	const [t] = useTranslation();
	const lang = useSelector((state: AppState) => state.project.currentProject.language);

	const { id, player, selectedSeason, onSeasonSelect, onPlayerTeamsSelect } = props;
	const [options, setOptions] = useState<Seasons[]>();

	const hasNotSelectedSeason = !selectedSeason || !selectedSeason.seasonId;

	useEffect(() => {
		setOptions([]);
		if (player) {
			HttpService.getPlayerSeasonStatistics(player, lang)
				.then((response: IPlayerStatisticsResponse) => {
					const modelList = mapResponseListToPlayerSeasonsModel(response.data);
					const options = createTournamentGroupOptions(modelList);
					setOptions(options);
				})
				.catch((e: unknown) => e);

			HttpService.getPlayerByIdV2(player, lang)
				.then((response: IPlayerInformationResponse) => {
					const preselectSeason = response &&
						response.data &&
						response.data.current_season && {
							seasonId: response.data.current_season.id,
							seasonName: response.data.current_season.name,
							tournamentName: response.data.current_season.tournament.name,
							tournamentId: response.data.current_season.tournament.id,
						};
					hasNotSelectedSeason && preselectSeason && onSeasonSelect(preselectSeason);
					const seasons = options ? [...options, preselectSeason] : createTournamentGroupOptions([preselectSeason]);
					setOptions(seasons);

					const playerTeams = response.data.teams.map(({ team }: IPlayerTeam) => team.id);
					playerTeams && playerTeams.length && onPlayerTeamsSelect(playerTeams);
				})
				.catch((e: unknown) => e);
		}
	}, [player]);

	useEffect(() => {
		if (options && options.length > 0 && hasNotSelectedSeason) {
			const defaultSeason = options[0].options && options[0].options.length > 0 ? options[0].options[0].data : null;
			defaultSeason && onSeasonSelect(defaultSeason);
		}
	}, [options]);

	const onChange = (option: ISelectOption<EntitySeasonsSelectModel> | null) => {
		if (option) {
			onSeasonSelect(option.data);
			return;
		}
		onSeasonSelect({} as EntitySeasonsSelectModel);
	};

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('tournament_seasons')}:</Label>
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					formatOptionLabel={customOption}
					options={options}
					value={selectedSeason && selectedSeason.seasonId && playerSeasonToOption(selectedSeason)}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable
					placeholder={t('select')}
					onChange={(selection) => onChange(selection as ISelectOption<EntitySeasonsSelectModel> | null)}
				/>
			</Col>
		</Row>
	);
};

export default PlayerSeasonStatisticsSelect;
