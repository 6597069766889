import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import InputDesign from '../../../../Partials/design-components/input/input';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DATA_QA } from '../../helpers/data-qa.properties';
import CustomEntityTranslations from './subcomponents/translations';
import CustomEntitySocials from './subcomponents/socials';
import WikiPageSelect from './subcomponents/wiki';
import { extractContentModeBasedOnUrl } from '../../../../../global-helpers/global.helpers';
import './details.scss';
import { ContentMode } from '../../../../../constants/content-types';
import CustomEntitiesHttpService from '../../../../../services/rest/custom-entities.http.service';
import { resetCustomEntity, updateCustomEntity } from '../../../../../store/action-creators/custom-entities-action-creator';
import ImagesUploads from './subcomponents/dnd-images';
import CustomEntityOptionals from './subcomponents/optional-fields';
import {
	CustomEntitiesTypes,
	DetailsErrorState,
	UpdateCustomEntityReduxType,
	areCoreFieldsValid,
	formatDataForPostRequest,
	shouldDisableSlug,
} from '../../helpers/custom-entities.helper';
import CustomEntityRoles from './subcomponents/roles';
import { slugifyString } from '../../../../Partials/Sidebar/seo-refactored/helpers/slug.helper';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import RoundedBlueButton from '../../../../Partials/design-components/buttons/rounded-blue';
import CoreFields from './subcomponents/core-fields';
import ContainedInFields from './subcomponents/contained-in-fields';
import { ICustomEntityType } from '../../models/models';
import './details.scss';
import OrganizationContained from './subcomponents/organization-contained';

type Props = {
	customEntity: ICustomEntityType;
	isCreateMode: boolean;
	resetCustomEntity: () => void;
	updateCustomEntity: UpdateCustomEntityReduxType;
	match: Record<string, any>;
	history: Record<string, any>;
};

const CustomEntityDetails: FunctionComponent<Props> = ({
	customEntity,
	isCreateMode,
	updateCustomEntity,
	resetCustomEntity,
	match,
	history,
}) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const coreFieldsSelectedYN = areCoreFieldsValid(customEntity);
	const [errorState, setErrorState] = useState<DetailsErrorState>({
		domain: false,
		entity_type: false,
		slug: false,
	});
	const isOrganization = customEntity.entity_type === CustomEntitiesTypes.ORGANIZATION;
	const isPlace = customEntity.entity_type === CustomEntitiesTypes.PLACE;
	const isPerson = customEntity.entity_type === CustomEntitiesTypes.PERSON;
	const entitySocialsFlag = isPlace || isOrganization || isPerson;

	useEffect(() => {
		if (!isCreateMode) {
			setIsLoading(true);
			const { entityType, id } = match.params;

			CustomEntitiesHttpService.getEntity(entityType, id)
				.then((response) => response.data)
				.then((data) => updateCustomEntity(data))
				.catch(() => toast.error(t('error_fetching_data')))
				.finally(() => setIsLoading(false));
		}

		return () => resetCustomEntity();
	}, [isCreateMode]);

	const onSave = () => {
		const isRole = customEntity.entity_type === CustomEntitiesTypes.ROLE;

		setErrorState({
			...errorState,
			domain: isRole ? false : !(customEntity.contained_in_domain && customEntity.contained_in_domain.id),
			entity_type: !customEntity.entity_type,
			slug: !(customEntity.slug && customEntity.slug.length > 0),
		});

		if (
			(isRole || (customEntity.contained_in_domain && customEntity.contained_in_domain.id)) &&
			customEntity.entity_type &&
			customEntity.slug
		) {
			const dataForSending = formatDataForPostRequest(customEntity);
			CustomEntitiesHttpService.saveEntity(dataForSending, history);
		} else {
			toast.error(t('fill_required_fields'));
		}
	};

	return (
		<div id={DATA_QA.CUSTOM_ENTITY_WRAPPER} className={isLoading ? 'loading-overlay' : ''}>
			<h1>{isCreateMode ? t('create_new_entity') : t('edit_entity')}</h1>
			<span>{t('custom_entity_description')}</span>
			<CoreFields isCreateMode={isCreateMode} errorState={errorState} />
			{(isPlace || isOrganization) && coreFieldsSelectedYN && <ContainedInFields isOrganization={isOrganization} />}
			{isPerson && coreFieldsSelectedYN && (
				<Row>
					<WikiPageSelect />
				</Row>
			)}
			{coreFieldsSelectedYN && isPerson && <CustomEntityRoles />}
			{coreFieldsSelectedYN && (
				<>
					<CustomEntityTranslations />
					<Row>
						<Col>
							<InputDesign
								invokeFunc={() => undefined}
								onBlurInvokeFunc={(text) => updateCustomEntity({ slug: slugifyString(text) })}
								fieldId='slug-input'
								labelText={t('slug')}
								placeholderText={t('slug')}
								value={customEntity.slug}
								disabled={shouldDisableSlug(customEntity.entity_type, isCreateMode)}
								errorMessage={errorState.slug ? t('slug_is_mandatory') : ''}
							/>
						</Col>
					</Row>
					<CustomEntityOptionals />
					<ImagesUploads />
					{entitySocialsFlag && <CustomEntitySocials />}
					<Row>
						<Col>
							<RoundedBlueButton text={t('save_entity')} onClickAction={onSave} title={t('save_entity')} />
						</Col>
					</Row>
				</>
			)}
		</div>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		isCreateMode: contentMode === ContentMode.CREATE,
		customEntity: state.customEntities[contentMode] || {},
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
		resetCustomEntity: () => dispatch(resetCustomEntity()),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(CustomEntityDetails);
