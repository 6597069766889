import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';
import { GenderEnum } from '../../../../../../Pages/Tournaments/constants/Contstants';

interface Props {
	label: string;
	value: string;
	flag: string;
	gender: string;
	selectAllSections: boolean;
	setSelectAllSections: (value: boolean) => void;
	selectedCompetitionIds: string[];
	setSelectedCompetitionIds: React.Dispatch<React.SetStateAction<string[]>>;
	setAllCompetitions: (value: boolean) => void;
}

export const CompetitionSection: FC<Props> = ({
	label,
	value,
	gender,
	selectAllSections,
	setSelectAllSections,
	selectedCompetitionIds,
	setSelectedCompetitionIds,
	setAllCompetitions,
}) => {
	const [t] = useTranslation();
	const [selectSection, setSelectSection] = useState(selectAllSections);

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.checked;

		if (inputValue) {
			setSelectedCompetitionIds((prevIds) => (prevIds.includes(value) ? prevIds : [...prevIds, value]));
		} else {
			setSelectedCompetitionIds((prevIds) => prevIds.filter((id) => id !== value));
			setSelectAllSections(false);
			setAllCompetitions(false);
		}
		setSelectSection(inputValue);
	};

	useEffect(() => {
		const isSelected = selectedCompetitionIds.find((id) => id === value) ? true : false;
		if (!selectAllSections && !isSelected) {
			setSelectSection(false);
			return;
		}
		setSelectSection(isSelected);
	}, [selectAllSections, selectedCompetitionIds]);

	const name = gender ? `${label} - (${gender === GenderEnum.MALE ? t('male') : t('female')})` : label;
	const genderLocatorValue = gender ? `-${gender}-` : '';

	return (
		<div id={`${label}${genderLocatorValue}competition-section-wrapper`} className='competition-section'>
			<FormGroup id={`${label}${genderLocatorValue}competition-section-container`} className='override-form-group'>
				<Input
					id={`${label}${genderLocatorValue}competition-section-input`}
					type='checkbox'
					name='radios'
					checked={selectSection}
					onChange={onChange}
				/>
				<Label check htmlFor={`${label}${genderLocatorValue}competition-section-input`} className='competition-section-label'>
					{name}
				</Label>
			</FormGroup>
		</div>
	);
};
