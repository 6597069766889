import { CustomEntitiesTypes } from '../../../../Pages/CustomEntities/helpers/custom-entities.helper';
import { CustomEntitiesSelectOption } from '../../../../Pages/CustomEntities/models/models';

const colors = {
	team: '#fc1111',
	player: '#27db3f',
	tournament: '#adccf7',
	competition: '#adccf7',
	coach: '#215bac',
	venue: '#000000',
	arena: '#000000',
	club: '#fc1111',
	team_president: '#ffea2c',
};

export const sportsConnectionColorStyles = {
	multiValue: (styles: any, state: any) => {
		const dataEntityType = state.data.data.entity_type;
		const color = colors[dataEntityType];

		return {
			...styles,
			borderBottom: `2px solid ${color}`,
			borderLeft: `2px solid ${color}`,
		};
	},
};

const customEntitiesColors = {
	[CustomEntitiesTypes.PERSON]: '#205BAC',
	[CustomEntitiesTypes.ORGANIZATION]: '#D800D8',
	[CustomEntitiesTypes.PLACE]: '#20AC80',
};

export const customConnectionColorStyles = {
	multiValue: (styles: any, state: { data: CustomEntitiesSelectOption }) => {
		const dataEntityType = state && state.data && state.data.additional && state.data.additional.entity_type;
		const color: string = (dataEntityType && customEntitiesColors[dataEntityType]) || 'transparent';

		return {
			...styles,
			borderBottom: `2px solid ${color}`,
			borderLeft: `2px solid ${color}`,
		};
	},
};
