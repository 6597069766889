import { UrlsJson } from './UrlsJson';
import UrlsBuilder from './UrlsBuilder';
import VideoUrls from './video-urls/video-urls';

export default class Urls {
	readonly externalUrl: string;
	readonly canonicalUrl: string;
	readonly publicUrlDesktop: string;
	readonly publicUrlMobile: string;
	readonly publicUrlAmp: string;
	readonly videoUrls: VideoUrls[];
	readonly originalSourceUrl: string;

	private constructor(
		externalUrl: string,
		canonicalUrl: string,
		publicUrlDesktop: string,
		publicUrlMobile: string,
		publicUrlAmp: string,
		videoUrls: VideoUrls[],
		originalSourceUrl: string,
	) {
		this.externalUrl = externalUrl;
		this.canonicalUrl = canonicalUrl;
		this.publicUrlDesktop = publicUrlDesktop;
		this.publicUrlMobile = publicUrlMobile;
		this.publicUrlAmp = publicUrlAmp;
		this.videoUrls = videoUrls;
		this.originalSourceUrl = originalSourceUrl;
	}

	toJSON(): UrlsJson {
		return {
			externalUrl: this.externalUrl,
			canonicalUrl: this.canonicalUrl,
			publicUrlDesktop: this.publicUrlDesktop,
			publicUrlMobile: this.publicUrlMobile,
			publicUrlAmp: this.publicUrlAmp,
			videoUrls: this.videoUrls,
			originalSourceUrl: this.originalSourceUrl,
		};
	}

	static fromJSON(json: UrlsJson): Urls {
		return new Urls(
			json.externalUrl,
			json.canonicalUrl,
			json.publicUrlDesktop,
			json.publicUrlMobile,
			json.publicUrlAmp,
			json.videoUrls,
			json.originalSourceUrl,
		);
	}

	static builder(urls?: Urls): UrlsBuilder {
		return new UrlsBuilder(urls);
	}
}
