import { call, put, takeEvery } from 'redux-saga/effects';
import { actionService } from '../../App';
import { REFRESH_TOKEN_KEY, TOKEN_KEY, TOKEN_USER_ID } from '../../constants/constants';
import i18n from '../../i18n';
import HttpService from '../../services/rest/HttpService';
import SentryService from '../../services/sentry/sentry';
import { authenticated } from '../action-creators/AuthActionCreator';
import { onError } from '../action-creators/GeneralActions';
import { returnObjectForProfileReceived } from '../action-creators/ProfileActionCreators';
import { REQUEST_USER_BUNDLE, userProjectsReceived } from '../action-creators/UserBundleActionCreators';
import { requestApiTokens, requestApiTokensFailed } from '../action-creators/ApiTokensCreators';
import i18next from 'i18next';

function* fetchUserBundle(action: any) {
	let isAuthenticated = false;

	try {
		let userCreds = action.payload;
		const token = yield call(HttpService.getToken, userCreds.username, userCreds.password);

		if (!token || token.status < 200 || token.status >= 300) {
			throw new Error(i18next.t('general_error_message'));
		}

		localStorage.setItem(TOKEN_KEY, `Bearer ${token.data.access_token}`);
		localStorage.setItem(REFRESH_TOKEN_KEY, token.data.refresh_token);
		isAuthenticated = true;
	} catch (error) {
		yield put(onError(error));
		actionService.emitError(error);
	}

	try {
		if (isAuthenticated) {
			const profile = yield call(HttpService.get, '/profile');
			const permissions = yield call(HttpService.get, `/admins/${profile.data.data.id}/permissions`);
			i18n.changeLanguage(profile.data.data.language);
			localStorage.setItem(TOKEN_USER_ID, profile.data.data.id);
			SentryService.setUser(profile.data.data.id);
			yield put(returnObjectForProfileReceived(profile, permissions));
		}
	} catch (error) {
		yield put(onError(error));
	}

	try {
		if (isAuthenticated) {
			let userId = localStorage.getItem(TOKEN_USER_ID);
			const projects = yield call(HttpService.get, `/admins/${userId}/projects?limit=200`);
			const tokens = yield call(HttpService.get, '/auth/access-tokens');

			yield put(userProjectsReceived(projects));
			yield put(authenticated(isAuthenticated));
			yield put(requestApiTokens(tokens));
		}
	} catch (error) {
		yield put(onError(error));
		yield put(requestApiTokensFailed(error));
	}
}

function* loginSaga() {
	yield takeEvery(REQUEST_USER_BUNDLE, fetchUserBundle);
}

export default loginSaga;
