import Project from '../../../../../models/project/Project';
import { availableContentTypes } from '../../../../../services/content-models-service/ContentModelService';

export enum UrlWhitelistType {
	ARTICLE = 'article',
	GALLERY = 'gallery',
	VIDEO = 'video',
	TAG = 'tag',
	CATEGORY = 'category',
}

export const checkIfContentIsWhitelisted = (contentType: availableContentTypes | null, projectWhitelist: string[]): boolean => {
	if (contentType && Object.values(UrlWhitelistType).includes(contentType as UrlWhitelistType)) {
		return projectWhitelist && projectWhitelist.includes(contentType);
	}

	return false;
};

export const checkIfProjectHasCanonicalUrlGeneration = (project: Project): boolean => {
	return project.urlGenerationEnabled;
};
