export interface ApiTokensData {
	data: ApiResponse;
}

interface ApiResponse {
	access_token: ApiTokens;
}

interface ApiTokens {
	'football-api': string;
	'autotagging-api-v2': string;
	'customization-api': string;
	'multisport-api': string;
	'multisport-api-admin': string;
	'search-api': string;
	'statistics-api': string;
	'client-api': string;
}

const TOKEN_KEYS = {
	'football-api': 'football-api-token',
	'autotagging-api-v2': 'autotagging-api-v2-token',
	'customization-api': 'customization-api-token',
	'multisport-api': 'multisport-api-token',
	'multisport-api-admin': 'multisport-api-admin-token',
	'search-api': 'search-api-token',
	'statistics-api': 'statistics-api-token',
	'client-api': 'client-api-token',
} as const;

const getStorageKey = (apiKey: keyof ApiTokens): string => TOKEN_KEYS[apiKey];

export const setApiTokens = (access_token: ApiTokens | null | undefined): void => {
	if (!access_token) return;

	Object.keys(TOKEN_KEYS).forEach((apiKey) => {
		const key = apiKey as keyof ApiTokens;
		const token = access_token[key];
		if (token) {
			localStorage.setItem(getStorageKey(key), token);
		}
	});
};

export const removeApiTokens = (): void => {
	Object.values(TOKEN_KEYS).forEach((storageKey) => {
		localStorage.removeItem(storageKey);
	});
};
