import axios from 'axios';
import { featuresService, multiLingualService } from '../../App';
import { store } from '../../store/store';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';
import { formatGeneralDateAsString, getTimezoneOffset } from '../../global-helpers/global-dates.helper';
import { PreviewDataForSave } from '../../views/Pages/Multisport-widget/components/modals/constants';

const baseURL = REACT_APP_URLS.REACT_APP_MULTISPORT_API_URL;
const MULTISPORT_API_AUTH = REACT_APP_URLS.REACT_APP_GET_MULTISPORT_AUTH();
const MULTISPORT_API_ADMIN_AUTH = REACT_APP_URLS.REACT_APP_GET_ADMIN_MULTISPORT_AUTH();

export default class MultisportHttpService {
	static generalUrl = '/multi-sport/events';
	static getProjectLanguage = (): string => {
		const reduxState = store.getState();
		return reduxState.project && reduxState.project.currentProject && reduxState.project.currentProject.language
			? reduxState.project.currentProject.language
			: 'en';
	};

	static getOffset = (date: Date | string) => getTimezoneOffset(store.getState().project.currentProject.timezone, date);

	static getOffsetQuery = (date: Date | string) => `&utc_offset=${this.getOffset(date)}`;
	static getTranslationLanguageQuery = (isFirstQuery: boolean = false) =>
		`${!isFirstQuery ? '&' : ''}translation_language=${this.getProjectLanguage()}`;

	static getGeneralInstanceHeaders = () => {
		return {
			'X-Project': `${store.getState().project.currentProject.domain}`,
			'Accept-Language': `${
				multiLingualService.checkIfProjectIsMultiLingual(store.getState().project.currentProject.languages) &&
				multiLingualService.checkRoutePath()
					? multiLingualService.setCorrectAcceptLanguageCode(store.getState().project.currentProject.languages.defaultLanguageCode.languageCode)
					: store.getState().project.currentProject.language
					? store.getState().project.currentProject.language.split('-')[0]
					: 'en'
			}`,
		};
	};

	static instance = () => {
		return axios.create({
			baseURL,
			timeout: 30000,
			headers: {
				...this.getGeneralInstanceHeaders(),
				Authorization: MULTISPORT_API_AUTH,
			},
		});
	};

	static adminInstance = () => {
		return axios.create({
			baseURL,
			timeout: 30000,
			headers: {
				...this.getGeneralInstanceHeaders(),
				Authorization: MULTISPORT_API_ADMIN_AUTH,
			},
		});
	};

	static getSportEventsByDate = (filterDate: Date, configurationDate: Date, configurationCompetitionList: string) => {
		const filterDateAsString = formatGeneralDateAsString(filterDate);
		const configurationDateAsString = formatGeneralDateAsString(configurationDate);

		return MultisportHttpService.instance().get(
			`${
				this.generalUrl
			}/list?filter_date=${filterDateAsString}&selection_date=${configurationDateAsString}&competition_list=${configurationCompetitionList}${this.getTranslationLanguageQuery()}${this.getOffsetQuery(
				configurationDate,
			)}`,
		);
	};

	static getPreviewEventsByDate = (configurationDate: Date, configurationCompetitionList: string) => {
		const configurationDateAsString = formatGeneralDateAsString(configurationDate);

		return MultisportHttpService.instance().get(
			`${this.generalUrl}/${configurationCompetitionList}/${configurationDateAsString}?${this.getTranslationLanguageQuery(
				true,
			)}${this.getOffsetQuery(configurationDate)}`,
		);
	};

	static savePreviewEvents = (configurationDate: Date, dataForSave: PreviewDataForSave[], configurationCompetitionList: string) => {
		const configurationDateAsString = formatGeneralDateAsString(configurationDate);

		return MultisportHttpService.adminInstance().put(
			`${this.generalUrl}/${configurationCompetitionList}/${configurationDateAsString}`,
			dataForSave,
		);
	};

	static getCompetitionListing = (sport: string, lang: string) => {
		const language = `${lang ? `translation_language=${lang}` : ''}`;
		let resultUrl = '/multi-sport/competitions/lists';
		try {
			const featureData = featuresService.checkFeatureDataField(sport) as Record<string, any>;
			const featureCompetitionList = featureData.competitionList || null;
			const competitionListQuery = featureCompetitionList ? `/${featureCompetitionList}?${language}` : `?${language}`;
			resultUrl += competitionListQuery;
		} catch (error) {}
		return MultisportHttpService.instance().get(resultUrl);
	};
}
