import React from 'react';
import _ from 'lodash';
import { Col, Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import EnumItem from '../../../../models/enum/EnumItem';
import EntityModel from '../models/entity.model';
import HttpService from '../../../../services/rest/HttpService';
import { itemsToOptions, itemToOption, optionToItem } from '../../../Partials/Shared/react-select-helpers/react-select.helpers';
import { customOption } from '../../../Partials/Blocky/partials/shared/custom-select-option';
import { remapEntitiesFromResponse } from '../helpers/translations.helpers';
import CountrySelect from '../../manual-data-entries/subcomponents/fields/country';
import LineupPlayerType from './lineup-player-type-select.component';
import EntitySelect from './entity-select.component';
import { TranslationEntities } from '../helpers/translations.constants';

type Properties = {
	t: any;
	entityType: EnumItem;
	entity: EntityModel;
	searchLanguage: EnumItem;
	onEntitySelect: (selection: any) => void;
};

const SearchEntitiesComponent: React.FunctionComponent<Properties> = (props) => {
	const { entityType, entity, t, onEntitySelect, searchLanguage } = props;

	const onSelect = (selection: any) => onEntitySelect(optionToItem(selection));

	const searchEntity = (search: string, callback: any) => {
		entityType &&
			searchLanguage &&
			HttpService.instanceFootball(searchLanguage.id)
				.get(`/search?query=${search}&entity_type=${entityType.name}`, null)
				.then((response: any) => {
					callback(itemsToOptions(remapEntitiesFromResponse(response.data, entityType.name), false, t, entityType.name));
				})
				.catch((e: any) => e);
	};

	if (entityType.name === TranslationEntities.COUNTRY) {
		return <CountrySelect searchLanguage={searchLanguage} t={t} onChange={onEntitySelect} value={entity} />;
	}

	if (entityType.name === TranslationEntities.MATCH_STATUS || entityType.name === TranslationEntities.STANDING_RULE) {
		return <EntitySelect type={entityType.name} searchLanguage={searchLanguage} t={t} onChange={onEntitySelect} value={entity} />;
	}

	if (entityType.name === TranslationEntities.LINEUP_PLAYER_TYPE) {
		return <LineupPlayerType searchLanguage={searchLanguage} t={t} onChange={onEntitySelect} value={entity} />;
	}

	return (
		<Col xs='6'>
			<Label>{t(entityType.name)}</Label>
			<div className='d-flex flex-direction-row flex-wrap'>
				<AsyncSelect
					inputId='translations-search-entity-input'
					className='w-100'
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					value={itemToOption(entity, false, t, entityType.name)}
					loadOptions={_.debounce(searchEntity, 500)}
					placeholder={`${t('search')} ${t(entityType.name)}`}
					onChange={onSelect}
					formatOptionLabel={customOption}
				/>
			</div>
		</Col>
	);
};

export default SearchEntitiesComponent;
