import axios from 'axios';
import { store } from '../../../../store/store';
import CustomBlockIconModel from '../custom-block/custom-block-icon.model';
import REACT_APP_URLS from '../../../../global-helpers/global-url.helpers';

const CUSTOMIZATION_API_URL = REACT_APP_URLS.REACT_APP_CUSTOMIZATION_API_BASE_URL;
const CUSTOMIZATION_API_AUTH = REACT_APP_URLS.REACT_APP_GET_CUSTOMIZATION_API_AUTH();
export const SUB_MENU_TYPE = 'ARTICLE_ORIGIN';

export default class CustomizationApiHttpService {
	static instance = (headers: any) => {
		return axios.create({
			baseURL: CUSTOMIZATION_API_URL,
			timeout: 30000,
			headers: {
				...headers,
				authorization: CUSTOMIZATION_API_AUTH,
			},
		});
	};

	static getCustomBlocksListing = () => {
		const project = store && store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };

		return CustomizationApiHttpService.instance(headers).get('/custom-blocks');
	};

	static getCustomBlock = (id: string) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };

		return CustomizationApiHttpService.instance(headers).get(`/custom-blocks/${id}`);
	};

	static updateCustomBlock = (
		id: string,
		name: string,
		applicationUrl: string,
		icon: CustomBlockIconModel = {
			type: 'ICON',
			value: 'fa fa-puzzle-piece',
		},
		status: string = 'DISABLED',
	) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };

		let payloadIcon = icon;

		if (payloadIcon.value === '') {
			payloadIcon.value = 'fa fa-';
		}

		let data = {
			name: name,
			icon: icon,
			status: status,
			application_url: applicationUrl,
		};

		return CustomizationApiHttpService.instance(headers).put(`/custom-blocks/${id}`, data);
	};

	static deleteCustomBlock = (id: string) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };

		return CustomizationApiHttpService.instance(headers).delete(`/custom-blocks/${id}`);
	};

	static postCustomBlock = (
		name: string,
		applicationUrl: string,
		icon: CustomBlockIconModel = {
			type: 'ICON',
			value: 'fa fa-puzzle-piece',
		},
		status: string = 'DISABLED',
	) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };

		let data = {
			name: name,
			icon: icon,
			status: status,
			application_url: applicationUrl,
		};

		return CustomizationApiHttpService.instance(headers).post(`/custom-blocks`, data);
	};

	static postToAdminActivity = () => {
		const project = store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };
		const adminId = store.getState().profile.profile.id;

		let data = {
			admin_id: adminId,
			event_type: SUB_MENU_TYPE,
			entity_id: '1',
		};

		return CustomizationApiHttpService.instance(headers).put(`/admin-activity`, data);
	};

	static getAdminActivity = () => {
		const project = store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };
		const adminId = store.getState().profile.profile.id;

		return CustomizationApiHttpService.instance(headers).get(`/admin-activity?admin_id=${adminId}&event_type=${SUB_MENU_TYPE}&entity_id=1`);
	};
}
