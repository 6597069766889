import React, { useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Properties, RequiredProperties } from './properties/UrlsProperties';
import RedirectTypeSelectContainer from './subcomponents/RedirectTypeSelectContainer';
import { ContentTypes } from '../../../../constants/content-types';
import ErrorAsync from '../../BaseComponents/ErrorComponent/ErrorAsync';
import { SocialMediaComponent } from './subcomponents/social-urls.component';
import VideoUrlsComponent from './subcomponents/video-urls/video-urls.component';
import VideoUrls from '../../../../models/urls/video-urls/video-urls';
import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { extractMainContentTypeBasedOnUrl } from '../../../../global-helpers/global.helpers';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import { checkIfContentIsWhitelisted, checkIfProjectHasCanonicalUrlGeneration } from '../urls-refactored/helpers/canonical-url.helper';
import './styles/info-panel.scss';

const UrlsComponent: React.FC<Properties> = ({ t, value, contentType, onChange }) => {
	const videoUrlsSelectionEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.VIDEO_URLS);
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const currentProject = useSelector((state: AppState) => state.project.currentProject);
	const isInWhitelist = checkIfContentIsWhitelisted(contentTypeFromUrl, currentProject.urlGenerationWhitelist);
	const urlAutoGenerationEnabled = checkIfProjectHasCanonicalUrlGeneration(currentProject);
	const [toggleInfoPanel, setToggleInfoPanel] = useState<boolean>(false);
	const isCanonicalUrlDisabled = isInWhitelist && urlAutoGenerationEnabled;

	const handleExternalUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange({ ...value, externalUrl: event.target.value });
	};

	const handleCanonicalUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange({ ...value, canonicalUrl: event.target.value });
	};

	const handleRedirectTypeSelect = (redirectType: string) => {
		onChange({ ...value, redirectType });
	};

	const handleVideoUrlsSelect = (videoUrls: VideoUrls[]) => {
		onChange({ ...value, videoUrls });
	};

	const onInfoToggle = () => setToggleInfoPanel(!toggleInfoPanel);

	return (
		<div id='sidebar-urls'>
			<Row>
				<Col>
					<FormGroup>
						<Label htmlFor='external_url'>{t('external_url')}</Label>
						<Input
							type='text'
							id='external_url'
							placeholder={t('external_url_placeholder')}
							value={value.externalUrl || ''}
							onChange={handleExternalUrlChange}
						/>
						<ErrorAsync t={t} errorType='external_url' />
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col>
					<FormGroup>
						<Label htmlFor='urls-type-of-redirect'>{t('type_redirect')}</Label>
						<RedirectTypeSelectContainer t={t} redirectType={value.redirectType || ''} onRedirectTypeChange={handleRedirectTypeSelect} />
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col>
					<FormGroup>
						<Label htmlFor='canonical_url'>{t('canonical_url')}</Label>
						{isCanonicalUrlDisabled && (
							<div className='canonical-url-info'>
								<div className='info-icon' onClick={onInfoToggle} />
								{toggleInfoPanel && (
									<div className='info-message'>
										<div className='info-message-description'> {t('canonical_url_info_description')} </div>
									</div>
								)}
							</div>
						)}
						<Input
							type='text'
							id='canonical_url'
							placeholder={t('canonical_url_placeholder')}
							value={value.canonicalUrl || ''}
							onChange={handleCanonicalUrlChange}
							disabled={isCanonicalUrlDisabled}
						/>
						<ErrorAsync t={t} errorType='canonical_url' />
					</FormGroup>
				</Col>
			</Row>

			{videoUrlsSelectionEnabled && contentType === ContentTypes.VIDEO && (
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor='title'>{t('video_types')}</Label>
							<VideoUrlsComponent t={t} urls={value.videoUrls || []} onVideoUrlsChange={handleVideoUrlsSelect} />
						</FormGroup>
					</Col>
				</Row>
			)}

			{(contentType === ContentTypes.ARTICLE || contentType === ContentTypes.VIDEO || contentType === ContentTypes.GALLERY) && (
				<>
					<Row className='mb-2'>
						<Col>
							<Label>{t('public_url_desktop')}</Label>
							<SocialMediaComponent t={t} url={value.publicUrlDesktop || ''} type='desktop' />
						</Col>
					</Row>

					<Row className='mb-2'>
						<Col>
							<Label>{t('public_url_mobile')}</Label>
							<SocialMediaComponent t={t} url={value.publicUrlMobile || ''} type='mobile' />
						</Col>
					</Row>

					<Row className='mb-2'>
						<Col>
							<Label>{t('public_url_amp')}</Label>
							<SocialMediaComponent t={t} url={value.publicUrlAmp || ''} type='amp' />
						</Col>
					</Row>
				</>
			)}
		</div>
	);
};

export default withTranslation()(UrlsComponent) as React.ComponentType<RequiredProperties>;
