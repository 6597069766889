import * as React from 'react';
import { Link } from 'react-router-dom';
import '../styles/rows.scss';
import { isExternalArticleSection } from '../../../../../../Resources/Articles/Helpers/ArticleHelper';
import RowNotificationsPill from '../../../../../design-components/notifications-pill/rows-notification-pill/rowNotificationPill.component';
import { store } from '../../../../../../../store/store';
import moment from 'moment';

type Properties = {
	canEditContent: boolean;
	editContentPath: string;
	analytics: any;
	contentTitle: string;
	strapline?: string;
	contentId?: string;
	showStatus?: boolean;
	active?: boolean;
	t?: any;
	titleStyle?: string;
	showLanguageFlag?: boolean;
	contentCreationDate?: string;
};

export const RowTitleLink: React.FunctionComponent<Properties> = ({
	canEditContent,
	editContentPath,
	contentTitle,
	analytics,
	strapline,
	contentId,
	showStatus,
	active,
	t,
	titleStyle,
	contentCreationDate,
}) => {
	const lastActivityTimestamp: string = store.getState().adminActivity.lastActivityTimestamp || null;

	const isNewContent =
		contentCreationDate && lastActivityTimestamp ? moment(contentCreationDate).isAfter(moment(lastActivityTimestamp)) : false;

	return (
		<div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
			<Link
				className={`${showStatus && !active ? 'inactive' : 'text-dark'}`}
				id={contentId}
				onClick={() => {
					analytics.sendEvent('Click', 'List Page', 'Edit Title');
				}}
				to={canEditContent ? editContentPath : '#'}
				style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
			>
				{strapline && strapline !== '' && (
					<span className={'pl-1 pr-1 d-inline-block'} style={{ backgroundColor: '#c5c5c5' }}>{`${strapline}`}</span>
				)}
				<span className={`pl-1 text-break ${titleStyle ? titleStyle : ''}`}>{contentTitle}</span>
				{showStatus && !active && <span className={'pl-1'}>({t('inactive')})</span>}
			</Link>
			{isExternalArticleSection() && isNewContent && (
				<div style={{ position: 'relative', right: '-10px', pointerEvents: 'none' }}>
					<RowNotificationsPill label='new' />
				</div>
			)}
		</div>
	);
};
