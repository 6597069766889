import React, { FunctionComponent, ChangeEvent, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import ErrorAsync from '../../../../BaseComponents/ErrorComponent/ErrorAsync';
import { DebounceInput } from 'react-debounce-input';
import { ReduxURLsProps } from '../../constants/redux-urls.attributes';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { checkIfContentIsWhitelisted, checkIfProjectHasCanonicalUrlGeneration } from '../../helpers/canonical-url.helper';
import { extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import './info-panel.scss';

type Properties = {
	t: any;
	canonicalUrl: string;
	onChange: (data: string, propertyName: ReduxURLsProps) => void;
};

const CanonicalUrlInput: FunctionComponent<Properties> = ({ t, canonicalUrl, onChange }) => {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const currentProject = useSelector((state: AppState) => state.project.currentProject);
	const isInWhitelist = checkIfContentIsWhitelisted(contentTypeFromUrl, currentProject.urlGenerationWhitelist);
	const urlAutoGenerationEnabled = checkIfProjectHasCanonicalUrlGeneration(currentProject);
	const [toggleInfoPanel, setToggleInfoPanel] = useState<boolean>(false);
	const isCanonicalUrlDisabled = isInWhitelist && urlAutoGenerationEnabled;

	const onCanonicalUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value || '';
		onChange(inputText, ReduxURLsProps.CANONICAL_URL);
	};

	const onInfoToggle = () => setToggleInfoPanel(!toggleInfoPanel);

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.CANONICAL_URL}>{t('canonical_url')}</Label>
					{isCanonicalUrlDisabled && (
						<div className='canonical-url-info'>
							<div className='info-icon' onClick={onInfoToggle} />
							{toggleInfoPanel && (
								<div className='info-message'>
									<div className='info-message-description'> {t('canonical_url_info_description')} </div>
								</div>
							)}
						</div>
					)}
					<DebounceInput
						id={DATA_QA_ATTRIBUTES.CANONICAL_URL}
						debounceTimeout={300}
						type='text'
						className='form-control'
						placeholder={t('canonical_url_placeholder')}
						value={canonicalUrl}
						onChange={onCanonicalUrlChange}
						disabled={isCanonicalUrlDisabled}
					/>
					<ErrorAsync t={t} errorType='external_url' />
				</FormGroup>
			</Col>
		</Row>
	);
};

export default CanonicalUrlInput;
