import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { extractContentModeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { updateCustomEntity } from '../../../../../../store/action-creators/custom-entities-action-creator';
import {
	UpdateCustomEntityReduxType,
	fetchOrganizationToOptions,
	fetchPlaceToOptions,
	getSelectedContainedInFieldOption,
	selectContainedInFields,
} from '../../../helpers/custom-entities.helper';
import { IOrganization, IPlace } from '../../../models/models';
import DynamicSelectInputDesign from '../../../../../Partials/design-components/dynamic-select/dynamic-select';
import { useTranslation } from 'react-i18next';
import WikiPageSelect from './wiki';

type Props = {
	customEntityContainedPlace: IPlace['contained_in_place'];
	customEntityContainedOrganization: IOrganization['contained_in_organization'];
	updateCustomEntity: UpdateCustomEntityReduxType;
	isOrganization: boolean;
};

const ContainedInFields: FunctionComponent<Props> = ({
	customEntityContainedPlace,
	customEntityContainedOrganization,
	updateCustomEntity,
	isOrganization,
}) => {
	const { t } = useTranslation();

	return (
		<div id={DATA_QA.OPTIONAL_FIELDS}>
			<Row>
				<Col md='6'>
					<DynamicSelectInputDesign
						invokeFunc={(option) => selectContainedInFields(option, updateCustomEntity, 'contained_in_place')}
						fetchResults={fetchPlaceToOptions}
						fieldId='contained_in_place'
						labelText={t('contained_in_place')}
						isClearable
						selectedValue={getSelectedContainedInFieldOption(customEntityContainedPlace)}
					/>
				</Col>
				{isOrganization && (
					<Col md='6'>
						<DynamicSelectInputDesign
							invokeFunc={(option) => selectContainedInFields(option, updateCustomEntity, 'contained_in_organization')}
							fetchResults={fetchOrganizationToOptions}
							fieldId='contained_in_organization'
							labelText={t('contained_in_organization')}
							isClearable
							selectedValue={getSelectedContainedInFieldOption(customEntityContainedOrganization)}
						/>
					</Col>
				)}
				<WikiPageSelect />
			</Row>
		</div>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		customEntityContainedPlace: state.customEntities[contentMode].contained_in_place || null,
		customEntityContainedOrganization: state.customEntities[contentMode].contained_in_organization || null,
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainedInFields);
